<template>
    <div class="layout-2">
        <div v-observer class="header-titles">
            <h2 class="title" v-html="header"></h2>
            <!--            <h2 class="title" v-html="secondLine"></h2>-->
            <!--            <h2 class="title" v-html="lastLine"></h2>-->
        </div>
        <div v-observer class="content-container">
            <div
                data-scroll
                :data-scroll-speed="firstItemSpeed"
                class="left-content content"
            >
                <div class="image-container">
                    <img :src="item1.image" class="image" alt="" />
                </div>
                <div v-observer class="text-content">
                    <header-title
                        :name="item1.title"
                        underline-color="#93272C"
                    />
                    <div class="about-list">
                        <div
                            v-for="(item, index) in item1.descriptions"
                            :key="index"
                            class="list-item"
                        >
                            <h2
                                class="header-title"
                                :style="{ color: item.color }"
                            >
                                {{ item.title }}
                            </h2>
                            <span
                                class="description"
                                v-html="item.content"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                data-scroll
                :data-scroll-speed="secondItemSpeed"
                class="right-content content"
            >
                <div class="image-container">
                    <img :src="item2.image" class="image" alt="" />
                </div>
                <div v-observer class="text-content">
                    <div class="about-list">
                        <div class="about-list">
                            <div
                                v-for="(item, index) in item2.descriptions"
                                :key="index"
                                class="list-item"
                            >
                                <h2
                                    class="header-title"
                                    :style="{ color: item.color }"
                                >
                                    {{ item.title }}
                                </h2>
                                <span
                                    class="description"
                                    v-html="item.content"
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderTitle from "@/components/custom/HeaderTitle";
import first from "lodash/first";
import last from "lodash/last";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        HeaderTitle
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        secondItemSpeed() {
            return this.device.size < 1025 ? 0 : 2;
        },
        firstItemSpeed() {
            return this.device.size < 1025 ? 0 : 0.5;
        },
        item1() {
            return first(
                this.data?.list?.map(item => ({
                    image: item?.image?.[0]?.devices[this.device?.type],
                    descriptions: item?.content?.tabs,
                    title: item.title,
                    color: item?.content?.tabs[0]?.color
                }))
            );
        },
        item2() {
            return last(
                this.data?.list?.map(item => ({
                    image: item?.image?.[0]?.devices[this.device?.type],
                    descriptions: item?.content?.tabs,
                    title: item.title,
                    color: item?.content?.tabs[0]?.color
                }))
            );
        },
        header() {
            return this.config?.header?.title;
        },
        wordLength() {
            return this.header?.split(" ")?.length;
        },
        firstLine() {
            return this.header
                ?.split(" ")
                ?.slice(0, Math.floor(this.wordLength / 3) + 1)
                ?.join(" ");
        },
        secondLine() {
            return this.header
                ?.split(" ")
                ?.slice(
                    Math.floor(this.wordLength / 3) + 1,
                    2 * (Math.floor(this.wordLength / 3) + 1)
                )
                ?.join(" ");
        },
        lastLine() {
            return this.header
                ?.split(" ")
                ?.slice(
                    2 * (Math.floor(this.wordLength / 3) + 1),
                    this.wordLength
                )
                ?.join(" ");
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.layout-2 /deep/ {
    padding-top: 144px;

    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        padding-top: 89px;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 55px;
    }

    .header-title {
        font-size: 46px;
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            font-size: 34px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 21px;
        }
    }

    .header-titles {
        &.observed {
            .title {
                line-height: 120px;
                transform: translateY(0);
                @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                    line-height: 80px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 768px) {
                    line-height: 60px;
                }
                @media only screen and (max-width: 767px) {
                    line-height: 33px;
                }
            }
        }

        .title {
            font-size: 55px;
            color: #0a141c;
            max-width: 1170px;
            text-align: center;
            font-weight: normal;
            margin: 0 auto;
            transition: all $ease-out 0.6s;
            transform: translateY(150px);
            line-height: 240px;

            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                font-size: 55px;
                max-width: 850px;
                transform: translateY(100px);
                line-height: 160px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                line-height: 120px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                font-size: 34px;
                max-width: 600px;
                transform: translateY(60px);
            }

            @media only screen and (max-width: 767px) {
                font-size: 21px;
                max-width: 300px;
                transform: translateY(40px);
                line-height: 80px;
            }

            @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                    transition-delay: $i/10 + s;
                }
            }

            span {
                color: #e5b676;
            }
        }
    }

    .content-container {
        margin-top: 101px;
        display: flex;
        text-align: left;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            margin-top: 75px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin-top: 50px;
        }
        @media only screen and (max-width: 767px) {
            display: block;
            margin-top: 34px;
        }

        &.observed {
            .image-container {
                .image {
                    transform: scale(1);
                    clip-path: inset(0px 0px 0px 0px) !important;
                }
            }
        }

        .image-container {
            overflow: hidden;
            width: 100%;
            @media only screen and (max-width: 767px) {
                width: calc(100% - 32px);
            }

            .image {
                width: 100%;
                transition: all 0.6s 0.1s $ease-out;
                transform: scale(1.2);
            }
        }

        .content {
            flex: 50%;
            flex-direction: column;
            display: flex;

            .text-content {
                margin-top: 131px;
                @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                    margin-top: 80px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    margin-top: 55px;
                }
                @media only screen and (max-width: 767px) {
                    margin-top: 34px;
                }

                &.observed {
                    .about-list {
                        .header-title,
                        .description {
                            transform: translateY(0);
                        }
                    }
                }

                .header-title {
                    line-height: unset;
                    font-weight: normal;
                }
            }

            &.left-content {
                align-items: flex-end;
                @media only screen and (max-width: 768px) {
                    align-items: center;
                }

                .text-content {
                    padding-left: 375px;
                    @media only screen and (max-width: 1700px) and (min-width: 1440px) {
                        padding-left: 165px;
                    }
                    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                        padding-left: 128px;
                    }
                    @media only screen and (max-width: 1365px) and (min-width: 768px) {
                        padding-left: 47px;
                    }
                    @media only screen and (max-width: 767px) {
                        padding: 0 16px;
                    }
                }

                .image {
                    clip-path: inset(100px 0px 0px 0px);
                }

                .about-list {
                    margin-top: 45px;
                    padding-right: 20px;
                    padding-left: 0;
                    @media only screen and (max-width: 767px) {
                        margin-top: 30px;
                    }
                }

                .list-item {
                    .header-title {
                        color: #e5b676;
                    }

                    &:last-child {
                        .header-title {
                            color: #e5b676;
                        }
                    }
                }
            }

            &.right-content {
                margin-top: 50px;
                align-items: flex-start;
                @media only screen and (max-width: 767px) {
                    align-items: center;
                    margin-top: 45px;
                }

                .text-content {
                    padding-right: 375px;
                    @media only screen and (max-width: 1700px) and (min-width: 1440px) {
                        padding-right: 165px;
                    }
                    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                        padding-right: 128px;
                    }
                    @media only screen and (max-width: 1365px) and (min-width: 768px) {
                        padding-right: 47px;
                    }
                    @media only screen and (max-width: 767px) {
                        padding: 0 16px;
                    }
                }

                .list-item {
                    .header-title {
                        color: #e5b676;
                    }

                    &:last-child {
                        .header-title {
                            color: #e5b676;
                        }
                    }
                }
            }

            .about-list {
                display: flex;
                flex-direction: column;
                grid-gap: 44px;
                padding-left: 20px;
                @media only screen and (max-width: 767px) {
                    grid-gap: 30px;
                    padding-left: 0;
                    padding-right: 0 !important;
                }

                .header-title {
                    font-weight: bold;
                    font-size: 21px;
                    transform: translateY(100px);
                    transition: all 0.6s 0.1s $ease-out;
                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        transform: translateY(60px);
                    }
                    @media only screen and (max-width: 767px) {
                        transform: translateY(30px);
                    }
                }

                .description {
                    font-size: 21px;
                    color: #0a141c;
                    margin-top: 15px;
                    display: block;
                    transform: translateY(100px);
                    transition: all 0.6s 0.2s $ease-out;
                    font-family: "Noto-Sans", "Noto-Sans-georgian";
                    line-height: 24px;
                    @media only screen and (max-width: 1440px) and (min-width: 768px) {
                        transform: translateY(60px);
                    }
                    @media only screen and (max-width: 767px) {
                        transform: translateY(30px);
                    }
                }
            }
        }
    }
}
</style>
